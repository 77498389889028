.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    line-height: 1.4;
    padding: 12px;
}

.header {
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 30px;
    line-height: 1.2;
    text-align: center;
}

@media (max-width: 599px) {
    .header {
        font-size: 40px;
    }
}

.subHeader {
    font-size: 22px;
    color: var(--text-secondary-color);
    text-align: center;
}

@media (max-width: 599px) {
    .subHeader {
        font-size: 18px;
    }
}


.logout {
    margin-top: 50px;
    font-size: 14px;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.button {
    margin-top: 30px;
    padding: 0px 40px;
    font-weight: 600;
}

.foundingMemberCard {
    margin-top: 60px;
    margin-bottom: 30px;
}

.animation {
    width: 300px;
    margin-bottom: 30px;
}