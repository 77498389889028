
.speaker {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.speakerExtended {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.speakerDetails {
    margin-left: 12px;
}

.speakerAvatar {
    border: 2px var(--primary) solid;
}

.speakerRole {
    margin-top: 5px;
    font-size: 11px;
    color: #a9acb0;
    text-align: center;
    text-transform: capitalize;
}

.speakerName {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    line-height: 1.3em;
}


.speakerTitle  {
    font-size: 12px;
    color: #a9acb0;
    line-height: 1.3em;
}