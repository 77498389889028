.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 20px;
    /* background-color: red; */
    border-bottom: 1px solid #ebebeb;
}

.headerContent {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
}

.contentWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 20px;
    height: 100%;
    /* background-color: blue; */
    align-items: center;
}

.content {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    width: 100%;
    /* background-color: green; */
    max-width: 1000px;
}

@media (max-width: 599px) {
    .content {
        margin-top: 30px;
        flex-direction: column;
    }
}

.leftSide {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    justify-content: center;
}

.logo {
    /* margin-left: -6px; */
    height: 28px;
    margin: 30px 0;
}

.helpButton {
    margin-left: auto;
    border-radius: 12px;
    font-size: 14px;
    padding: 6px 12px;
    cursor: pointer;
    border: 1px solid rgb(3, 1, 24);
}

.loadingAnimation {
    margin-top: -60px;
    margin-bottom: 30px;
    width: 60px;

}

@media (max-width: 599px) {
    .loadingAnimation {
        margin-top: 0px;
    }
}

.mainText {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4em;

}

.infoBubble {
    /* margin-top: 20px; */
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: #fffadc;
    max-width: 400px;
    height: fit-content;
    /* border: 1px solid #fff9d1; */
}

@media (max-width: 599px) {
    .infoBubble {
        margin-top: 30px;
    }
}


.infoTextContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.infoIcon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    display: flex;
    border-radius: 10px;
    margin-right: 12px;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.infoText {
    line-height: 1.4;
    font-size: 18px;
}

.spatialImage {
    border-radius: 8px;
    width: 100%;
}

.imageContainer {
    position: relative;
    width: 100%;
}

.handAnimation {
    width: 150px;
    position: absolute;
    bottom: 50px;
    right: 30px;
}

@media (max-width: 599px) {
    .handAnimation {
        bottom: 10%;
        right: 10px;
    }
}
