.container {
    width: 220px;
    height: 220px;
    cursor: pointer;
    position: relative;
    user-select: none;
}


@media(max-width: 599px) {
    .container {
        width: 100%;
    }
}

.card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text {
margin-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    flex-direction: row;
    font-size: 15px;
}

.titles {
    margin-top: 8px;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.title {
    line-height: 1.3;
    font-weight: 600;
    color: var(--text-secondary-color) !important;
    margin-bottom: 0px !important;
}

.companyName {
    margin-top: 5px;
}

.onlineBubble {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #30a24c;
    margin-right: 6px;
}

.role {
    background-color: var(--primary-contrast-light);
    color: var(--primary);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 600;
    border-radius: 1px;
}

.premiumBadge {
    padding: 2px 4px;
    background-color: #cba83c;
    color: white;
    font-size: 8px;
    border-radius: 4px;
    position: absolute;
    font-weight: 600;
    text-transform: uppercase;
    top: 12px;
    left: 12px;
}