.container {
    height: auto;
    min-height: 100vh;
    width: 100%;
    line-height: 1.4em;
    background-color: var(--background-color);
}

.upperPart {
    height: 430px;
    background-color: white;
    box-shadow: 0px 10px 30px rgba(239, 239, 239, 0.4);
    overflow: hidden;
}

.upperPartBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.background {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 400px;
}

.avatar {
    margin-top: 20px;
    border: 3px solid var(--primary);
}

.userDetails {
    margin-top: 30px;
    text-align: center;
}

.name {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
}

.title {
    font-size: 16px;
    color: var(--text-secondary-color);
}

.foundingMemberBadge {
    padding: 3px 8px;
    background-color: var(--primary-contrast-light);
    color: var(--primary-contrast-dark);
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 16px;
}

.premiumBadge {
    padding:4px 8px;
    background-color: #cba83c;
    color: white;
    font-size: 12px;
    border-radius: 4px;
    position: absolute;
    font-weight: 600;
    text-transform: uppercase;
    top: 12px;
    left: 12px;
}

.bottomPart {
    overflow: scroll;
    padding: 20px 0px;
    /* height: 100%; */
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding: 0 12px;
}

.card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 30px rgba(197, 197, 197, 0.40);
    border-radius: 10px;
    height: 180px;
    padding: 12px;
}

.sideIcon {
    position: absolute;
    left: 12px;
    top: 12px;
    font-size: 24px;
}

.date {
    font-weight: 900;
    font-size: 28px;
}

.smallHeader {
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    bottom: 24px;
    padding: 0 12px;
    width: 100%;
    text-align: center;
}

.largeText {
    font-size: 38px;
    font-weight: 900;
}


.contactDetails {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 26px;
}

.circle {
    width: 70px;
    height: 70px;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 50%;
    cursor: pointer;
    font-size: 28px;
    box-shadow: 0px 0px 20px rgba(133, 133, 133, 0);
}

.editProfile {
    /* position: absolute; */
    bottom: 22px;
    color: #070e22a8;
    display: flex;
    width: 100%;
    font-size: 16px;
    text-decoration: underline;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.logo {
    position: absolute;
    height: 18px;
    top: 12px;
    right: 12px;
    filter: brightness(0) invert(1);
    
}