.container {
    width: 100%;
}

.image {
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin-right: 16px;
}

.company {
    display: flex;
    flex-direction: row;
    
}

.description {
    color: var(--text-secondary-color);
    font-size: 15px;
    
}

.name {
    color: #3f3f3f;
    margin-bottom: 3px;
    font-weight: bold;
}